import Layout from "../components/layout";
import Seo from "../components/seo";
import * as React from "react";

const InstructionPage = () => (
    <Layout>
        <Seo title="Instructions"/>
        <div className="overflow-hidden lg:relative bg-white py-4 sm:py-8 lg:py-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-xl sm:px-6 lg:max-w-5xl lg:px-8">
                <h1>
                    <span className="block text-3xl font-extrabold text-gray-900 sm:text-4xl">Instructions</span>
                    <span className="block text-lg text-gray-900"></span>
                </h1>
            </div>
        </div>
    </Layout>
)

export default InstructionPage
